import themeConfig from '@configs/themeConfig'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cacheCleaner: '123',
  colorScheme: localStorage.getItem('skin') ? localStorage.getItem('skin') : 'light',
  companyConfiguration: null,
  contentWidth: themeConfig.layout.contentWidth,
  footerType: themeConfig.layout.footer.type,
  isRTL: themeConfig.layout.isRTL,
  layout: themeConfig.layout.type,
  lastLayout: themeConfig.layout.type,
  menuCollapsed: themeConfig.layout.menu.isCollapsed,
  menuHidden: themeConfig.layout.menu.isHidden,
  navbarColor: themeConfig.layout.navbar.backgroundColor,
  navbarType: themeConfig.layout.navbar.type,
  navigateToChargeFromSaleNoteNormalFlow: false,
  navigateToChargeFromSaleNoteSaleFlow: false,
  navigateToSaleNoteFromAgeOfCustomerBalancesReport: false,
  navigateToSaleNoteFromCharge: false,
  navigateToSaleNoteFromListOfSalesOperationsReport: false,
  precioDefault: 1,
  precioDefaultText: 'precio1',
  selectedImage: null,
  selectedTab: 'Home',
  deletingRecord: false,
  mapLatitude: 0,
  mapLongitude: 0,
  userInformation: null,
  openImageViewer: false,
  routerTransition: themeConfig.layout.routerTransition,
  selectSalesSaleStep: 'Catalog',
  selectSalesSaleSaleStep: 'Sale_Note'
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleCacheCleaner: (state, action) => {
      state.cacheCleaner = action.payload
    },
    handleLayout: (state, action) => {
      state.layout = action.payload
    },
    handleNavigateToChargeFromSaleNoteNormalFlow: (state, action) => {
      state.navigateToChargeFromSaleNoteNormalFlow = action.payload
    },
    handleNavigateToChargeFromSaleNoteSaleFlow: (state, action) => {
      state.navigateToChargeFromSaleNoteSaleFlow = action.payload
    },
    handleColorScheme: (state, action) => {
      state.colorScheme = action.payload
    },
    handleCompanyConfiguration: (state, action) => {
      state.companyConfiguration = action.payload
    },
    handleNavigateToSaleNoteFromAgeOfCustomerBalancesReport: (state, action) => {
      state.navigateToSaleNoteFromAgeOfCustomerBalancesReport = action.payload
    },
    handleNavigateToSaleNoteFromCharge: (state, action) => {
      state.navigateToSaleNoteFromCharge = action.payload
    },
    handleNavigateToSaleNoteFromListOfSalesOperationsReport: (state, action) => {
      state.navigateToSaleNoteFromListOfSalesOperationsReport = action.payload
    },
    handleMap: (state, action) => {
      state.mapLatitude = action.payload.mapLatitude
      state.mapLongitude = action.payload.mapLongitude
    },
    handlePrecioDefault: (state, action) => {
      state.precioDefault = action.payload.precioDefault
      state.precioDefaultText = action.payload.precioDefaultText
    },
    handleRTL: (state, action) => {
      state.isRTL = action.payload
      window.localStorage.setItem('direction', JSON.stringify(action.payload))
    },
    handleSetDeletingRecord: (state, action) => {
      state.deletingRecord = action.payload
    },
    handleSelectTab: (state, action) => {
      state.selectedTab = action.payload
    },
    handleUserInformation: (state, action) => {
      state.userInformation = action.payload
    },
    handleOpenImageViewer: (state, action) => {
      state.openImageViewer = action.payload
    },
    handleSelectImage: (state, action) => {
      state.selectedImage = action.payload
    },
    handleSelectSalesSaleStep: (state, action) => {
      state.selectSalesSaleStep = action.payload
    },
    handleSelectSalesSaleSaleStep: (state, action) => {
      state.selectSalesSaleSaleStep = action.payload
    },
    handleLayoutOnLogout: (state) => {
      state.cacheCleaner = '123'
      state.companyConfiguration = null
      state.navigateToChargeFromSaleNoteNormalFlow = false
      state.navigateToChargeFromSaleNoteSaleFlow = false
      state.navigateToSaleNoteFromAgeOfCustomerBalancesReport = false
      state.navigateToSaleNoteFromCharge = false
      state.navigateToSaleNoteFromListOfSalesOperationsReport = false
      state.precioDefault = 1
      state.precioDefaultText = 'precio1'
      state.selectedImage = null
      state.selectedTab = 'Home'
      state.deletingRecord = false
      state.mapLatitude = 0
      state.mapLongitude = 0
      state.userInformation = null
      state.openImageViewer = false
      state.selectSalesSaleStep = 'Catalog'
      state.selectSalesSaleSaleStep = 'Sale_Note'
    },
    handleFooterType: (state, action) => {
      state.footerType = action.payload
    },
    handleNavbarType: (state, action) => {
      state.navbarType = action.payload
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload
      window.localStorage.setItem('menuCollapsed', JSON.stringify(action.payload))
    },
    handleRouterTransition: (state, action) => {
      state.routerTransition = action.payload
    }
  }
})

export const {
  handleCacheCleaner,
  handleNavigateToChargeFromSaleNoteNormalFlow,
  handleNavigateToChargeFromSaleNoteSaleFlow,
  handleColorScheme,
  handleCompanyConfiguration,
  handleNavigateToSaleNoteFromAgeOfCustomerBalancesReport,
  handleNavigateToSaleNoteFromCharge,
  handleNavigateToSaleNoteFromListOfSalesOperationsReport,
  handleMap,
  handlePrecioDefault,
  handleSetDeletingRecord,
  handleSelectImage,
  handleSelectTab,
  handleUserInformation,
  handleLayoutOnLogout,
  handleOpenImageViewer,
  handleSelectSalesSaleStep,
  handleSelectSalesSaleSaleStep,
  handleRTL,
  handleSkin,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
  handleRouterTransition
} = layoutSlice.actions

export default layoutSlice.reducer
