import { createSlice } from '@reduxjs/toolkit'

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: [],
    selectedContact: null,
    selectedContactsStep: 'Contacts'
  },
  reducers: {
    handleContactsOnLogout: (state) => {
      state.contacts = []
      state.selectedContact = null
      state.selectedContactsStep = 'Contacts'
    },
    handleCreateContact: (state, action) => {
      state.contacts = [action.payload, ...state.contacts]
    },
    handleDeleteContact: (state, action) => {
      state.contacts = [
        ...state?.contacts?.slice(0, action.payload),
        ...state?.contacts?.slice(action.payload + 1)
      ]
    },
    handleGetContact: (state, action) => {
      state.contacts = state?.contacts?.map((e) => {
        return e.entidadId.toString() === action.payload.entidadId.toString() ? action.payload : e
      })
    },
    handleGetContacts: (state, action) => {
      state.contacts = action.payload
    },
    handleSelectContact: (state, action) => {
      state.selectedContact = action.payload
    },
    handleSelectContactsStep: (state, action) => {
      state.selectedContactsStep = action.payload
    }
  }
})

export const {
  handleContactsOnLogout,
  handleCreateContact,
  handleDeleteContact,
  handleGetContact,
  handleGetContacts,
  handleSelectContact,
  handleSelectContactsStep
} = contactsSlice.actions

export default contactsSlice.reducer
