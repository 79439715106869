import { createSlice } from '@reduxjs/toolkit'

export const userProfilesSlice = createSlice({
  name: 'userProfiles',
  initialState: {
    selectedUserProfile: null,
    selectedUserProfilesStep: 'User_Profiles',
    userProfiles: []
  },
  reducers: {
    handleCreateUserProfile: (state, action) => {
      state.userProfiles = [action.payload, ...state.userProfiles]
    },
    handleDeleteUserProfile: (state, action) => {
      state.userProfiles = [
        ...state?.userProfiles?.slice(0, action.payload),
        ...state?.userProfiles?.slice(action.payload + 1)
      ]
    },
    handleGetUserProfile: (state, action) => {
      state.userProfiles = state?.userProfiles?.map((e) => {
        return e.perfilId.toString() === action.payload.perfilId.toString() ? action.payload : e
      })
    },
    handleGetUserProfiles: (state, action) => {
      state.userProfiles = action.payload
    },
    handleSelectUserProfile: (state, action) => {
      state.selectedUserProfile = action.payload
    },
    handleSelectUserProfilesStep: (state, action) => {
      state.selectedUserProfilesStep = action.payload
    },
    handleUserProfilesOnLogout: (state) => {
      state.selectedUserProfile = null
      state.selectedUserProfilesStep = 'User_Profiles'
      state.userProfiles = []
    }
  }
})

export const {
  handleCreateUserProfile,
  handleDeleteUserProfile,
  handleGetUserProfile,
  handleGetUserProfiles,
  handleSelectUserProfile,
  handleSelectUserProfilesStep,
  handleUserProfilesOnLogout
} = userProfilesSlice.actions

export default userProfilesSlice.reducer
