import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    indicators: [],
    lastSevenDaysSales: [],
    lastSevenDaysSalesBiggestNumber: null,
    yAxisLabelTextsOnLastSevenDaysSales: []
  },
  reducers: {
    handleHomeOnLogout: (state) => {
      state.indicators = []
      state.lastSevenDaysSales = []
      state.yAxisLabelTextsOnLastSevenDaysSales = []
    },
    handleIndicators: (state, action) => {
      state.indicators = action.payload
    },
    handleIndicatorsAccountsReceivable: (state, action) => {
      state.indicators[2] = {
        ...state.indicators[2],
        importe: state.indicators[2]?.importe + action.payload
      }
    },
    handleIndicatorsSalesOfTheDay: (state, action) => {
      state.indicators[0] = {
        ...state.indicators[0],
        importe: state.indicators[0]?.importe + action.payload
      }
    },
    handleLastSevenDaysSales: (state, action) => {
      state.lastSevenDaysSales = action.payload
    },
    handleLastSevenDaysSalesBiggestNumber: (state, action) => {
      state.lastSevenDaysSalesBiggestNumber = action.payload
    },
    handleSalesOfTheDay: (state, action) => {
      state.lastSevenDaysSales = state?.lastSevenDaysSales?.map((e) => {
        return e.id === action.payload.id ? { ...e, value: e.value + action.payload.value } : e
      })
    },
    handleYAxisLabelTextsOnLastSevenDaysSales: (state, action) => {
      state.yAxisLabelTextsOnLastSevenDaysSales = action.payload
    }
  }
})

export const {
  handleHomeOnLogout,
  handleIndicators,
  handleIndicatorsAccountsReceivable,
  handleIndicatorsSalesOfTheDay,
  handleLastSevenDaysSales,
  handleLastSevenDaysSalesBiggestNumber,
  handleSalesOfTheDay,
  handleYAxisLabelTextsOnLastSevenDaysSales
} = homeSlice.actions

export default homeSlice.reducer
