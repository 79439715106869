import { createSlice } from '@reduxjs/toolkit'

export const productsAndServicesSlice = createSlice({
  name: 'productsAndServices',
  initialState: {
    productsAndServices: [],
    selectedProductAndService: null,
    selectedProductsAndServicesStep: 'Products_And_Services',
    selectedProductsAndServicesProductAndServiceStep: 'Default'
  },
  reducers: {
    handleCreateProductAndService: (state, action) => {
      state.productsAndServices = [action.payload, ...state.productsAndServices]
    },
    handleDeleteProductAndService: (state, action) => {
      state.productsAndServices = [
        ...state?.productsAndServices?.slice(0, action.payload),
        ...state?.productsAndServices?.slice(action.payload + 1)
      ]
    },
    handleGetProductAndService: (state, action) => {
      state.productsAndServices = state?.productsAndServices?.map((e) => {
        return e.conceptoId.toString() === action.payload.conceptoId.toString() ? action.payload : e
      })
    },
    handleGetProductsAndServices: (state, action) => {
      state.productsAndServices = action.payload
    },
    handleProductsAndServicesOnLogout: (state) => {
      state.productsAndServices = []
      state.selectedProductAndService = null
      state.selectedProductsAndServicesStep = 'Products_And_Services'
      state.selectedProductsAndServicesProductAndServiceStep = 'Default'
    },
    handleSelectProductAndService: (state, action) => {
      state.selectedProductAndService = action.payload
    },
    handleSelectProductsAndServicesStep: (state, action) => {
      state.selectedProductsAndServicesStep = action.payload
    },
    handleSelectProductsAndServicesProductAndServiceStep: (state, action) => {
      state.selectedProductsAndServicesProductAndServiceStep = action.payload
    }
  }
})

export const {
  handleCreateProductAndService,
  handleDeleteProductAndService,
  handleGetProductAndService,
  handleGetProductsAndServices,
  handleProductsAndServicesOnLogout,
  handleSelectProductAndService,
  handleSelectProductsAndServicesStep,
  handleSelectProductsAndServicesProductAndServiceStep
} = productsAndServicesSlice.actions

export default productsAndServicesSlice.reducer
