import { createSlice } from '@reduxjs/toolkit'

export const cashAndBankAccountsSlice = createSlice({
  name: 'cashAndBankAccounts',
  initialState: {
    cashAndBankAccounts: [],
    selectedCashAndBankAccount: null,
    selectedCashAndBankAccountsStep: 'Cash_And_Bank_Accounts'
  },
  reducers: {
    handleCashAndBankAccountsOnLogout: (state) => {
      state.cashAndBankAccounts = []
      state.selectedCashAndBankAccount = null
      state.selectedCashAndBankAccountsStep = 'Cash_And_Bank_Accounts'
    },
    handleCreateCashAndBankAccount: (state, action) => {
      state.cashAndBankAccounts = [action.payload, ...state.cashAndBankAccounts]
    },
    handleDeleteCashAndBankAccount: (state, action) => {
      state.cashAndBankAccounts = [
        ...state?.cashAndBankAccounts?.slice(0, action.payload),
        ...state?.cashAndBankAccounts?.slice(action.payload + 1)
      ]
    },
    handleGetCashAndBankAccount: (state, action) => {
      state.cashAndBankAccounts = state?.cashAndBankAccounts?.map((e) => {
        return e.cuentaId.toString() === action.payload.cuentaId.toString() ? action.payload : e
      })
    },
    handleGetCashAndBankAccounts: (state, action) => {
      state.cashAndBankAccounts = action.payload
    },
    handleSelectCashAndBankAccount: (state, action) => {
      state.selectedCashAndBankAccount = action.payload
    },
    handleSelectCashAndBankAccountsStep: (state, action) => {
      state.selectedCashAndBankAccountsStep = action.payload
    }
  }
})

export const {
  handleCashAndBankAccountsOnLogout,
  handleCreateCashAndBankAccount,
  handleDeleteCashAndBankAccount,
  handleGetCashAndBankAccount,
  handleGetCashAndBankAccounts,
  handleSelectCashAndBankAccount,
  handleSelectCashAndBankAccountsStep
} = cashAndBankAccountsSlice.actions

export default cashAndBankAccountsSlice.reducer
