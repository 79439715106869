/* eslint-disable multiline-ternary */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */

import { createSlice } from '@reduxjs/toolkit'

export const saleSlice = createSlice({
  name: 'sale',
  initialState: {
    cartItems: [],
    impuestos: 0,
    impuestosISRRetenido: 0,
    impuestosIVARetenido: 0,
    productsAndServicesWithPrice: [],
    reloadProductsAndServicesWithPrice: true,
    sale: {},
    sales: [],
    selectedPriceList: {
      activo: null,
      comentario: null,
      listaId: null,
      monedaId: null,
      monedaNombre: null,
      nombre: null,
      sucursalId: null,
      sucursalNombre: null,
      listaPreciosDetalle: [],
      precioIncluyeImpuestos: null
    },
    selectedSale: null,
    sellStep: 'Products',
    subtotal: 0,
    total: 0,
    uniqueCartItems: []
  },
  reducers: {
    handleAddCartItem: (state, action) => {
      state.cartItems = [action.payload, ...state.cartItems]
      state.subtotal = parseFloat(parseFloat(state.subtotal) + action.payload.precio).toFixed(2)
      state.impuestos = parseFloat(
        parseFloat(state.impuestos) +
          action.payload?.precio * action.payload?.impuesto1_valor -
          action.payload?.precio * action.payload?.impuesto3_ret_valor -
          action.payload?.precio * action.payload?.impuesto4_ret_valor
      ).toFixed(2)
      state.impuestosIVARetenido = parseFloat(
        parseFloat(state.impuestosIVARetenido) +
          action.payload?.precio * action.payload?.impuesto3_ret_valor
      ).toFixed(2)
      state.impuestosISRRetenido = parseFloat(
        parseFloat(state.impuestosISRRetenido) +
          action.payload?.precio * action.payload?.impuesto4_ret_valor
      ).toFixed(2)
      state.total = parseFloat(
        parseFloat(state.total) +
          action.payload?.precio +
          action.payload?.precio * action.payload?.impuesto1_valor -
          action.payload?.precio * action.payload?.impuesto3_ret_valor -
          action.payload?.precio * action.payload?.impuesto4_ret_valor
      ).toFixed(2)
    },
    handleCancelSale: (state, action) => {
      state.sales = state.sales.map((e) =>
        e.transaccionId.toString() === action.payload.transaccionId.toString()
          ? {
              ...e,
              cancelado: action.payload.cancelado,
              estado: 'Cancelado'
            }
          : e
      )
    },
    handleCartItems: (state, action) => {
      state.cartItems = action.payload
    },
    handleClearSale: (state) => {
      state.cartItems = []
      state.subtotal = 0
      state.total = 0
      state.impuestos = 0
      state.impuestosISRRetenido = 0
      state.impuestosIVARetenido = 0
    },
    handleCreateSale: (state, action) => {
      state.sales = [action.payload, ...state.sales]
    },
    handleCustomSwipeable: (state, action) => {
      state.subtotal = parseFloat(
        parseFloat(state.subtotal) - parseFloat(action.payload?.subtotal)
      ).toFixed(2)

      state.impuestos = parseFloat(
        parseFloat(state.impuestos) - parseFloat(action.payload?.impuestos)
      ).toFixed(2)

      state.impuestosIVARetenido = parseFloat(
        parseFloat(state.impuestosIVARetenido) - parseFloat(action.payload?.impuestosIVARetenido)
      ).toFixed(2)

      state.impuestosISRRetenido = parseFloat(
        parseFloat(state.impuestosISRRetenido) - parseFloat(action.payload?.impuestosISRRetenido)
      ).toFixed(2)

      state.total = parseFloat(
        parseFloat(state.total) - parseFloat(action.payload?.total).toFixed(2)
      )
    },
    handleEditSale: (state, action) => {
      state.sales = state.sales.map((e) =>
        e.transaccionId.toString() === action.payload.transaccionId.toString()
          ? {
              ...e,
              bitacora: action.payload.bitacora,
              cancelado: action.payload.cancelado,
              cfdi: action.payload.cfdi,
              clienteId: action.payload.clienteId,
              clienteNombre: action.payload.clienteNombre,
              cobros: action.payload.cobros,
              descuento: action.payload.descuento,
              estado: action.payload.estado,
              fecha: action.payload.fecha,
              folio: action.payload.folio,
              formaId: action.payload.formaId,
              formaPagoNombre: action.payload.formaPagoNombre,
              importe_a_cobrar: action.payload.saldo,
              impuesto1: action.payload.impuesto1,
              productosServicios: action.payload.productosServicios,
              saldo: action.payload.saldo,
              subtotal: action.payload.subtotal,
              total: action.payload.total,
              transaccionId: action.payload.transaccionId
            }
          : e
      )
    },
    handleGetSaleNotePdf: (state, action) => {
      state.sales = state.sales.map((e) =>
        e.transaccionId.toString() === action.payload.transaccionId.toString()
          ? {
              ...e,
              archivoURL: action.payload.archivoURL
            }
          : e
      )
    },
    handleProductAndServicesWithPrices: (state, action) => {
      state.productsAndServicesWithPrice = action.payload
    },
    handleRemoveCartItem: (state, action) => {
      state.cartItems = [
        ...state?.cartItems?.slice(0, action.payload.productAndServiceIndex),
        ...state?.cartItems?.slice(action.payload.productAndServiceIndex + 1)
      ]
      state.subtotal = parseFloat(
        parseFloat(state.subtotal) - parseFloat(action.payload?.product?.precio)
      ).toFixed(2)
      state.impuestos = parseFloat(
        parseFloat(state.impuestos) -
          parseFloat(
            action.payload?.product?.precio * action.payload?.product?.impuesto1_valor -
              action.payload?.product?.precio * action.payload?.product?.impuesto3_ret_valor -
              action.payload?.product?.precio * action.payload?.product?.impuesto4_ret_valor
          )
      ).toFixed(2)
      state.impuestosIVARetenido = parseFloat(
        parseFloat(state.impuestosIVARetenido) -
          parseFloat(action.payload?.product?.precio * action.payload?.product?.impuesto3_ret_valor)
      ).toFixed(2)
      state.impuestosISRRetenido = parseFloat(
        parseFloat(state.impuestosISRRetenido) -
          parseFloat(action.payload?.product?.precio * action.payload?.product?.impuesto4_ret_valor)
      ).toFixed(2)
      state.total = parseFloat(
        parseFloat(state.total) -
          parseFloat(
            action.payload?.product?.precio +
              (action.payload?.product?.precio * action.payload?.product?.impuesto1_valor -
                action.payload?.product?.precio * action.payload?.product?.impuesto3_ret_valor -
                action.payload?.product?.precio * action.payload?.product?.impuesto4_ret_valor)
          ).toFixed(2)
      )
    },
    handleReloadProductsAndServicesWithPrice: (state, action) => {
      state.reloadProductsAndServicesWithPrice = action.payload
    },
    handleSale: (state, action) => {
      state.sale = action.payload
    },
    handleSales: (state, action) => {
      state.sales = action.payload
    },
    handleSaleOnLogout: (state) => {
      state.cartItems = []
      state.impuestos = 0
      state.impuestosISRRetenido = 0
      state.impuestosIVARetenido = 0
      state.productsAndServicesWithPrice = []
      state.reloadProductsAndServicesWithPrice = true
      state.sale = {}
      state.sales = []
      state.selectedPriceList = {
        activo: null,
        comentario: null,
        listaId: null,
        monedaId: null,
        monedaNombre: null,
        nombre: null,
        sucursalId: null,
        sucursalNombre: null,
        listaPreciosDetalle: [],
        precioIncluyeImpuestos: null
      }
      state.selectedSale = null
      state.sellStep = 'Products'
      state.subtotal = 0
      state.total = 0
      state.uniqueCartItems = []
    },
    handleSelectPriceList: (state, action) => {
      state.activo = action.payload.activo
      state.comentario = action.payload.comentario
      state.listaId = action.payload.listaId
      state.monedaId = action.payload.monedaId
      state.monedaNombre = action.payload.monedaNombre
      state.nombre = action.payload.nombre
      state.sucursalId = action.payload.sucursalId
      state.sucursalNombre = action.payload.sucursalNombre
      state.listaPreciosDetalle = action.payload.listaPreciosDetalle
      state.precioIncluyeImpuestos = action.payload.precioIncluyeImpuestos
    },
    handleSelectPricelistOnLogin: (state, action) => {
      state.selectedPriceList.listaId = action.payload.listaId
      state.selectedPriceList.listaPreciosDetalle = []
      state.selectedPriceList.nombre = action.payload.listaNombre
    },
    handleSelectPricelistOnChangeBranchOfficeOnMenu: (state, action) => {
      state.selectedPriceList.listaId = action.payload.listaId
      state.selectedPriceList.listaPreciosDetalle = []
      state.selectedPriceList.nombre = action.payload.listaNombre
    },
    handleSelectPricelistOnChangePriceListOnMenu: (state, action) => {
      state.selectedPriceList.listaId = action.payload.listaId
      state.selectedPriceList.listaPreciosDetalle = []
      state.selectedPriceList.nombre = action.payload.listaNombre
    },
    handleSelectSale: (state, action) => {
      state.selectedSale = action.payload
    },
    handleSellStep: (state, action) => {
      state.sellStep = action.payload
    },
    handleUniqueCartItems: (state, action) => {
      state.uniqueCartItems = action.payload
    },
    handleUpdateSale: (state, action) => {
      state.sale = action.payload
    },
    handleEditProductAndServiceWithPrice: (state, action) => {
      state.productsAndServicesWithPrice = state?.productsAndServicesWithPrice?.map((e) =>
        e.conceptoId.toString() === action.payload.conceptoId.toString()
          ? { ...e, codigo_barras: action.payload.codigo_barras }
          : e
      )
    }
  }
})

export const {
  handleAddCartItem,
  handleCancelSale,
  handleCartItems,
  handleClearSale,
  handleCreateSale,
  handleCustomSwipeable,
  handleGetSaleNotePdf,
  handleEditSale,
  handleProductAndServicesWithPrices,
  handleRemoveCartItem,
  handleReloadProductsAndServicesWithPrice,
  handleSale,
  handleSales,
  handleSaleOnLogout,
  handleSelectPriceList,
  handleSelectPricelistOnLogin,
  handleSelectPricelistOnChangeBranchOfficeOnMenu,
  handleSelectPricelistOnChangePriceListOnMenu,
  handleSelectSale,
  handleSellStep,
  handleUniqueCartItems,
  handleUpdateSale,
  handleEditProductAndServiceWithPrice
} = saleSlice.actions

export default saleSlice.reducer
