import { createSlice } from '@reduxjs/toolkit'

export const companyConfigurationSlice = createSlice({
  name: 'companyConfiguration',
  initialState: {
    emailContacto: null,
    imgLarge: null,
    imgSmall: null,
    listaId: null,
    listaNombre: null,
    precioDefault: 1,
    precioDefaultText: 'precio1',
    organizacionApellidoMaterno: null,
    organizacionApellidoPaterno: null,
    organizacionCalle: null,
    organizacionCiudad: null,
    organizacionCodigoPostal: null,
    organizacionColonia: null,
    organizaciones: null,
    organizacionEstado: null,
    organizacionId: null,
    organizacionManejaPropina: null,
    organizacionMunicipio: null,
    organizacionNombre: null,
    organizacionNoExterior: null,
    organizacionNoInterior: null,
    organizacionPaginaWeb: null,
    organizacionRazonSocial: null,
    organizacionRFC: null,
    organizacionTelefono: null,
    regimenId: null,
    regimenNombre: null,
    tipoPersonaId: null,
    tipoPersonaNombre: null
  },
  reducers: {
    handleBranchOfficeOnMenu: (state, action) => {
      state.listaId = action.payload.listaId
      state.listaNombre = action.payload.listaNombre
      state.sucursalId = action.payload.sucursalId
      state.sucursalNombre = action.payload.sucursalNombre
    },
    handleCompanyConfiguration: (state, action) => {
      state.emailContacto = action.payload.emailContacto
      state.imgLarge = action.payload.imgLarge
      state.imgSmall = action.payload.imgSmall
      state.organizacionApellidoMaterno = action.payload.organizacionApellidoMaterno
      state.organizacionApellidoPaterno = action.payload.organizacionApellidoPaterno
      state.organizacionCalle = action.payload.organizacionCalle
      state.organizacionCiudad = action.payload.organizacionCiudad
      state.organizacionCodigoPostal = action.payload.organizacionCodigoPostal
      state.organizacionColonia = action.payload.organizacionColonia
      state.organizacionEstado = action.payload.organizacionEstado
      state.organizacionManejaPropina = action.payload.organizacionManejaPropina
      state.organizacionMunicipio = action.payload.organizacionMunicipio
      state.organizacionNombre = action.payload.organizacionNombre
      state.organizacionNoExterior = action.payload.organizacionNoExterior
      state.organizacionNoInterior = action.payload.organizacionNoInterior
      state.organizacionPaginaWeb = action.payload.organizacionPaginaWeb
      state.organizacionRazonSocial = action.payload.organizacionRazonSocial
      state.organizacionRFC = action.payload.organizacionRFC
      state.organizacionTelefono = action.payload.organizacionTelefono
      state.regimenId = action.payload.regimenId
      state.regimenNombre = action.payload.regimenNombre
      state.tipoPersonaId = action.payload.tipoPersonaId
      state.tipoPersonaNombre = action.payload.tipoPersonaNombre
    },
    handleCompanyConfigurationOnLogin: (state, action) => {
      state.listaId = action.payload.listaId
      state.listaNombre = action.payload.listaNombre
      state.organizaciones = action.payload.organizaciones
      state.organizacionId = action.payload.organizacionId
      state.organizacionManejaPropina = action.payload.organizacionManejaPropina
      state.organizacionNombre = action.payload.organizacionNombre
      state.organizacionRazonSocial = action.payload.organizacionRazonSocial
      state.sucursalId = action.payload.sucursalId
      state.sucursalNombre = action.payload.sucursalNombre
    },
    handleCompanyConfigurationOnLogout: (state) => {
      state.emailContacto = null
      state.imgLarge = null
      state.imgSmall = null
      state.listaId = null
      state.listaNombre = null
      state.precioDefault = 1
      state.precioDefaultText = 'precio1'
      state.organizacionApellidoMaterno = null
      state.organizacionApellidoPaterno = null
      state.organizacionCalle = null
      state.organizacionCiudad = null
      state.organizacionCodigoPostal = null
      state.organizacionColonia = null
      state.organizacionEstado = null
      state.organizaciones = null
      state.organizacionId = null
      state.organizacionManejaPropina = null
      state.organizacionMunicipio = null
      state.organizacionNombre = null
      state.organizacionNoExterior = null
      state.organizacionNoInterior = null
      state.organizacionPaginaWeb = null
      state.organizacionRazonSocial = null
      state.organizacionRFC = null
      state.organizacionTelefono = null
      state.regimenId = null
      state.regimenNombre = null
      state.tipoPersonaId = null
      state.tipoPersonaNombre = null
    },
    handleDefaultPrice: (state, action) => {
      state.precioDefault = action.payload.precioDefault
      state.precioDefaultText = action.payload.precioDefaultText
    },
    handlePriceListOnMenu: (state, action) => {
      state.listaId = action.payload.listaId
      state.listaNombre = action.payload.listaNombre
    },
    handleCompanyConfigurationUseTips: (state, action) => {
      state.organizacionManejaPropina = action.payload.organizacionManejaPropina
    }
  }
})

export const {
  handleBranchOfficeOnMenu,
  handleCompanyConfiguration,
  handleCompanyConfigurationOnLogin,
  handleCompanyConfigurationOnLogout,
  handleCompanyConfigurationUseTips,
  handleDefaultPrice,
  handlePriceListOnMenu
} = companyConfigurationSlice.actions

export default companyConfigurationSlice.reducer
