import { createSlice } from '@reduxjs/toolkit'

export const priceListsSlice = createSlice({
  name: 'priceLists',
  initialState: {
    priceLists: [],
    selectedPriceList: null,
    selectedPriceListsStep: 'Price_Lists'
  },
  reducers: {
    handleCreatePriceList: (state, action) => {
      state.priceLists = [action.payload, ...state.priceLists]
    },
    handleDeletePriceList: (state, action) => {
      state.priceLists = [
        ...state?.priceLists?.slice(0, action.payload),
        ...state?.priceLists?.slice(action.payload + 1)
      ]
    },
    handleGetPriceList: (state, action) => {
      state.priceLists = state?.priceLists?.map((e) => {
        return e.listaId.toString() === action.payload.listaId.toString() ? action.payload : e
      })
    },
    handleGetPriceLists: (state, action) => {
      state.priceLists = action.payload
    },
    handlePriceListsOnLogout: (state) => {
      state.priceLists = []
      state.selectedPriceList = null
      state.selectedPriceListsStep = 'Price_Lists'
    },
    handleSelectPriceList: (state, action) => {
      state.selectedPriceList = action.payload
    },
    handleSelectPriceListsStep: (state, action) => {
      state.selectedPriceListsStep = action.payload
    }
  }
})

export const {
  handleCreatePriceList,
  handleDeletePriceList,
  handleGetPriceList,
  handleGetPriceLists,
  handlePriceListsOnLogout,
  handleSelectPriceList,
  handleSelectPriceListsStep
} = priceListsSlice.actions

export default priceListsSlice.reducer
