import sale from './reducers/saleReducer'
import home from './reducers/homeReducer'
import users from './reducers/usersReducer'
import combos from './reducers/combosReducer'
import layout from './reducers/layoutReducer'
import navbar from './reducers/navbarReducer'
import charges from './reducers/chargesReducer'
import contacts from './reducers/contactsReducer'
import priceLists from './reducers/priceListsReducer'
import userProfiles from './reducers/userProfilesReducer'
import branchOffices from './reducers/branchOfficesReducer'
import myInformation from './reducers/myInformationReducer'
import authentication from './reducers/authenticationReducer'
import cashAndBankAccounts from './reducers/cashAndBankAccountsReducer'
import productsAndServices from './reducers/productsAndServicesReducer'
import companyConfiguration from './reducers/companyConfigurationReducer'
import contactClassifications from './reducers/contactClassificationsReducer'
import productAndServiceClassifications from './reducers/productAndServiceClassificationsReducer'
import productAndServiceClassificationsActives from './reducers/productAndServiceClassificationsActivesReducer'

const rootReducer = {
  authentication,
  branchOffices,
  cashAndBankAccounts,
  charges,
  contacts,
  contactClassifications,
  combos,
  companyConfiguration,
  home,
  layout,
  myInformation,
  navbar,
  priceLists,
  productsAndServices,
  productAndServiceClassifications,
  productAndServiceClassificationsActives,
  sale,
  userProfiles,
  users
}

export default rootReducer
