/* eslint-disable multiline-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */

import { createSlice } from '@reduxjs/toolkit'

export const chargesSlice = createSlice({
  name: 'charges',
  initialState: {
    charges: [],
    selectedCharge: null,
    selectedChargesStep: 'Charges'
  },
  reducers: {
    handleChargesOnLogout: (state) => {
      state.charges = []
      state.selectedCharge = null
      state.selectedChargesStep = 'Charges'
    },
    handleEditCharge: (state, action) => {
      state.charges = state.charges.map((e) =>
        e.transaccionId.toString() === action.payload.transaccionId.toString()
          ? {
              ...e,
              bitacora: action.payload.bitacora,
              cfdi: action.payload.cfdi,
              cliente: action.payload.cliente,
              clienteId: action.payload.clienteId,
              cuenta: action.payload.cuenta,
              cuentaId: action.payload.cuentaId,
              documentos: action.payload.documentos,
              fecha: action.payload.fecha,
              folio: action.payload.folio,
              forma: action.payload.forma,
              formaId: action.payload.formaId,
              referencia: action.payload.referencia,
              saldo: action.payload.saldo,
              subtotal: action.payload.subtotal,
              total: action.payload.total,
              transaccionId: action.payload.transaccionId
            }
          : e
      )
    },
    handleGetCharges: (state, action) => {
      state.charges = action.payload
    },
    handleSelectCharge: (state, action) => {
      state.selectedCharge = action.payload
    },
    handleSelectChargeStep: (state, action) => {
      state.selectedChargesStep = action.payload
    }
  }
})

export const {
  handleChargesOnLogout,
  handleEditCharge,
  handleGetCharges,
  handleSelectCharge,
  handleSelectChargeStep
} = chargesSlice.actions

export default chargesSlice.reducer
