import { createSlice } from '@reduxjs/toolkit'

export const branchOfficesSlice = createSlice({
  name: 'branchOffices',
  initialState: {
    branchOffices: [],
    selectedBranchOffice: null,
    selectedBranchOfficesStep: 'Branch_Offices'
  },
  reducers: {
    handleBranchOfficesOnLogout: (state) => {
      state.branchOffices = []
      state.selectedBranchOffice = null
      state.selectedBranchOfficesStep = 'Branch_Offices'
    },
    handleCreateBranchOffice: (state, action) => {
      state.branchOffices = [action.payload, ...state.branchOffices]
    },
    handleDeleteBranchOffice: (state, action) => {
      state.branchOffices = [
        ...state?.branchOffices?.slice(0, action.payload),
        ...state?.branchOffices?.slice(action.payload + 1)
      ]
    },
    handleGetBranchOffice: (state, action) => {
      state.branchOffices = state?.branchOffices?.map((e) => {
        return e.sucursalId.toString() === action.payload.sucursalId.toString() ? action.payload : e
      })
    },
    handleGetBranchOffices: (state, action) => {
      state.branchOffices = action.payload
    },
    handleSelectBranchOffice: (state, action) => {
      state.selectedBranchOffice = action.payload
    },
    handleSelectBranchOfficesStep: (state, action) => {
      state.selectedBranchOfficesStep = action.payload
    }
  }
})

export const {
  handleBranchOfficesOnLogout,
  handleCreateBranchOffice,
  handleDeleteBranchOffice,
  handleGetBranchOffice,
  handleGetBranchOffices,
  handleSelectBranchOffice,
  handleSelectBranchOfficesStep
} = branchOfficesSlice.actions

export default branchOfficesSlice.reducer
