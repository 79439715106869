import { createSlice } from '@reduxjs/toolkit'

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    authToken: null,
    contrasena: null,
    correo_electronico: null,
    cuentaId: null,
    onboarded: null,
    organizacionId: null,
    organizacion: null,
    organizacionManejaPropina: null,
    organizacionRazonSocial: null,
    recordarme: null,
    sucursalId: null,
    sucursalNombre: null
  },
  reducers: {
    handleCompanyConfigurationUseTips: (state, action) => {
      state.organizacionManejaPropina = action.payload
    },
    handleLogin: (state, action) => {
      state.authToken = action.payload.authToken
      state.cuentaId = action.payload.cuentaId
    },
    handleOrganizacion: (state, action) => {
      state.organizacion = action.payload.organizacion
      state.organizacionRazonSocial = action.payload.organizacionRazonSocial
    },
    handleUser: (state, action) => {
      state.organizacion = action.payload.organizacion
      state.organizacionRazonSocial = action.payload.organizacionRazonSocial
    },
    handleLoginCredentials: (state, action) => {
      state.contrasena = action.payload.contrasena
      state.correo_electronico = action.payload.correo_electronico
      state.recordarme = action.payload.recordarme
    },
    handleAuthenticationOnLogout: (state) => {
      state.authToken = null
      state.cuentaId = null
      state.onboarded = null
      state.organizacionId = null
      state.organizacion = null
      state.organizacionManejaPropina = null
      state.organizacionRazonSocial = null
      state.sucursalId = null
      state.sucursalNombre = null
    },
    handleOnboarded: (state) => {
      state.onboarded = true
    }
  }
})

export const {
  handleAuthenticationOnLogout,
  handleCompanyConfigurationUseTips,
  handleLogin,
  handleLoginCredentials,
  handleLogout,
  handleOnboarded,
  handleOrganizacion
} = authenticationSlice.actions

export default authenticationSlice.reducer
