import { createSlice } from '@reduxjs/toolkit'

export const myInformationSlice = createSlice({
  name: 'myInformation',
  initialState: {
    apellido: null,
    celular: null,
    contoId: null,
    email: null,
    fechaNacimiento: null,
    imagen: null,
    nombre: null,
    nombreCompleto: null,
    notificacionAplicacionActualizacion: null,
    notificacionAplicacionNoticias: null,
    notificacionActividadDeposito: null,
    notificacionActividadCorte: null,
    passwordActual: null,
    passwordNuevo: null,
    passwordNuevoConfirmar: null,
    sexoId: null,
    usuarioId: null
  },
  reducers: {
    handleMyInformation: (state, action) => {
      state.apellido = action.payload.apellido
      state.celular = action.payload.celular
      state.contoId = action.payload.contoId
      state.email = action.payload.email
      state.fechaNacimiento = action.payload.fechaNacimiento
      state.imagen = action.payload.imagen
      state.nombre = action.payload.nombre
      state.nombreCompleto = action.payload.nombreCompleto
      state.notificacionAplicacionActualizacion = action.payload.notificacionAplicacionActualizacion
      state.notificacionAplicacionNoticias = action.payload.notificacionAplicacionNoticias
      state.notificacionActividadDeposito = action.payload.notificacionActividadDeposito
      state.notificacionActividadCorte = action.payload.notificacionActividadCorte
      state.sexoId = action.payload.sexoId
      state.passwordActual = action.payload.passwordActual
      state.passwordNuevo = action.payload.passwordNuevo
      state.passwordNuevoConfirmar = action.payload.passwordNuevoConfirmar
      state.usuarioId = action.payload.usuarioId
    },
    handleMyInformationOnLogin: (state, action) => {
      state.email = action.payload.email
      state.imagen = action.payload.imagen
      state.nombre = action.payload.nombre
      state.usuarioId = action.payload.usuarioId
    },
    handleMyInformationOnLogout: (state) => {
      state.apellido = null
      state.celular = null
      state.contoId = null
      state.email = null
      state.fechaNacimiento = null
      state.imagen = null
      state.nombre = null
      state.nombreCompleto = null
      state.notificacionAplicacionActualizacion = null
      state.notificacionAplicacionNoticias = null
      state.notificacionActividadDeposito = null
      state.notificacionActividadCorte = null
      state.passwordActual = null
      state.passwordNuevo = null
      state.passwordNuevoConfirmar = null
      state.sexoId = null
      state.usuarioId = null
    }
  }
})

export const { handleMyInformation, handleMyInformationOnLogin, handleMyInformationOnLogout } =
  myInformationSlice.actions

export default myInformationSlice.reducer
