/* eslint-disable multiline-ternary */

import { createSlice } from '@reduxjs/toolkit'

export const contactClassificationsSlice = createSlice({
  name: 'contactClassifications',
  initialState: {
    contactClassifications: [],
    selectedContactClassification: null,
    selectedContactClassificationsStep: 'Contact_Classifications'
  },
  reducers: {
    handleContactClassificationsOnLogout: (state) => {
      state.contactClassifications = []
      state.selectedContactClassification = null
      state.selectedContactClassificationsStep = 'Contact_Classifications'
    },
    handleCreateContactClassification: (state, action) => {
      state.contactClassifications = [action.payload, ...state.contactClassifications]
    },
    handleDeleteContactClassification: (state, action) => {
      state.contactClassifications = [
        ...state?.contactClassifications?.slice(0, action.payload),
        ...state?.contactClassifications?.slice(action.payload + 1)
      ]
    },
    handleGetContactClassification: (state, action) => {
      state.contactClassifications = state?.contactClassifications?.map((e) => {
        return e.clasificacionId.toString() === action.payload.clasificacionId.toString()
          ? action.payload
          : e
      })
    },
    handleGetContactClassifications: (state, action) => {
      state.contactClassifications = action.payload
    },
    handleUpdateContactClassificationsSuperiorClassifications: (state, action) => {
      state.contactClassifications = state?.contactClassifications?.map((e) => {
        return e.clasificacionId.toString() !== action.payload.clasificacionId.toString()
          ? { ...e, clasificacionPadreOptions: [] }
          : e
      })
    },
    handleSelectContactClassification: (state, action) => {
      state.selectedContactClassification = action.payload
    },
    handleSelectContactClassificationsStep: (state, action) => {
      state.selectedContactClassificationsStep = action.payload
    }
  }
})

export const {
  handleContactClassificationsOnLogout,
  handleCreateContactClassification,
  handleDeleteContactClassification,
  handleGetContactClassification,
  handleGetContactClassifications,
  handleUpdateContactClassificationsSuperiorClassifications,
  handleSelectContactClassification,
  handleSelectContactClassificationsStep
} = contactClassificationsSlice.actions

export default contactClassificationsSlice.reducer
