import { createSlice } from '@reduxjs/toolkit'

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    selectedUser: null,
    selectedUsersStep: 'Users',
    users: []
  },
  reducers: {
    handleCreateUser: (state, action) => {
      state.users = [action.payload, ...state.users]
    },
    handleDeleteUser: (state, action) => {
      state.users = [
        ...state?.users?.slice(0, action.payload),
        ...state?.users?.slice(action.payload + 1)
      ]
    },
    handleGetUser: (state, action) => {
      state.users = state?.users?.map((e) => {
        return e.usuarioId.toString() === action.payload.usuarioId.toString() ? action.payload : e
      })
    },
    handleGetUsers: (state, action) => {
      state.users = action.payload
    },
    handleSelectUser: (state, action) => {
      state.selectedUser = action.payload
    },
    handleSelectUsersStep: (state, action) => {
      state.selectedUsersStep = action.payload
    },
    handleUsersOnLogout: (state) => {
      state.selectedUser = null
      state.selectedUsersStep = 'User_Profiles'
      state.users = []
    }
  }
})

export const {
  handleCreateUser,
  handleDeleteUser,
  handleGetUser,
  handleGetUsers,
  handleSelectUser,
  handleSelectUsersStep,
  handleUsersOnLogout
} = usersSlice.actions

export default usersSlice.reducer
