import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dsClasificacionSuperiorContacto: [],
  dsClasificacionSuperiorProducto: [],
  dsCmbBancos: [],
  dsCmbContactos: [],
  dsCmbContactosSale: [],
  dsCmbCriteriosAntiguedad: [],
  dsCmbCuentasBancos: [],
  dsCmbFormasPago: [],
  dsCmbFormasPagoCuenta: [],
  dsCmbListaPrecio: [],
  dsCmbListaPrecioOnMenu: [],
  dsCmbMetodoPagoVenta: [],
  dsCmbMonedas: [],
  dsCmbPreciosDefault: [
    { precioId: 1, precioNombre: 'precio_1' },
    { precioId: 2, precioNombre: 'precio_2' },
    { precioId: 3, precioNombre: 'precio_3' },
    { precioId: 4, precioNombre: 'precio_4' }
  ],
  dsCmbPerfiles: [],
  dsCmbProductoSAT: [],
  dsCmbRegimenesFiscales: [],
  dsCmbSucursales: [],
  dsCmbSucursalIVAVenta: [],
  dsCmbTipoCuentaBancos: [],
  dsCmbTipoPersona: [],
  dsCmbTipoProducto: [],
  dsCmbUnidadesMedida: [],
  dsCmbUsoCFDI: [],
  dsCmbUsuarios: []
}

export const combosSlice = createSlice({
  name: 'combos',
  initialState,
  reducers: {
    handleDsClasificacionSuperiorContacto: (state, action) => {
      state.dsClasificacionSuperiorContacto = action.payload
    },
    handleDsClasificacionSuperiorProducto: (state, action) => {
      state.dsClasificacionSuperiorProducto = action.payload
    },
    handleDsCmbBancos: (state, action) => {
      state.dsCmbBancos = action.payload
    },
    handleDsCmbContactos: (state, action) => {
      state.dsCmbContactos = action.payload
    },
    handleDsCmbContactosSale: (state, action) => {
      state.dsCmbContactosSale = action.payload
    },
    handleDsCmbCriteriosAntiguedad: (state, action) => {
      state.dsCmbCriteriosAntiguedad = action.payload
    },
    handleDsCmbCuentasBancos: (state, action) => {
      state.dsCmbCuentasBancos = action.payload
    },
    handleDsCmbFormasPago: (state, action) => {
      state.dsCmbFormasPago = action.payload
    },
    handleDsCmbFormasPagoCuenta: (state, action) => {
      state.dsCmbFormasPagoCuenta = action.payload
    },
    handleDsCmbListaPrecio: (state, action) => {
      state.dsCmbListaPrecio = action.payload
    },
    handleDsCmbListaPrecioOnMenu: (state, action) => {
      state.dsCmbListaPrecioOnMenu = action.payload
    },
    handleDsCmbMetodoPagoVenta: (state, action) => {
      state.dsCmbMetodoPagoVenta = action.payload
    },
    handleDsCmbMonedas: (state, action) => {
      state.dsCmbMonedas = action.payload
    },
    handleDsCmbPerfiles: (state, action) => {
      state.dsCmbPerfiles = action.payload
    },
    handleDsCmbProductoSAT: (state, action) => {
      state.dsCmbProductoSAT = action.payload
    },
    handleDsCmbRegimenesFiscales: (state, action) => {
      state.dsCmbRegimenesFiscales = action.payload
    },
    handleDsCmbSucursales: (state, action) => {
      state.dsCmbSucursales = action.payload
    },
    handleDsCmbSucursalIVAVenta: (state, action) => {
      state.dsCmbSucursalIVAVenta = action.payload
    },
    handleDsTipoCuentaBancos: (state, action) => {
      state.dsCmbTipoCuentaBancos = action.payload
    },
    handleDsCmbTipoPersona: (state, action) => {
      state.dsCmbTipoPersona = action.payload
    },
    handleDsCmbTipoProducto: (state, action) => {
      state.dsCmbTipoProducto = action.payload
    },
    handleDsCmbUnidadesMedida: (state, action) => {
      state.dsCmbUnidadesMedida = action.payload
    },
    handleDsCmbUsoCFDI: (state, action) => {
      state.dsCmbUsoCFDI = action.payload
    },
    handleDsCmbUsuarios: (state, action) => {
      state.dsCmbUsuarios = action.payload
    },
    handleCombosOnLogout: (state) => {
      state.dsClasificacionSuperiorContacto = []
      state.dsClasificacionSuperiorProducto = []
      state.dsCmbBancos = []
      state.dsCmbContactos = []
      state.dsCmbContactosSale = []
      state.dsCmbCriteriosAntiguedad = []
      state.dsCmbCuentasBancos = []
      state.dsCmbFormasPago = []
      state.dsCmbFormasPagoCuenta = []
      state.dsCmbListaPrecio = []
      state.dsCmbListaPrecioOnMenu = []
      state.dsCmbMetodoPagoVenta = []
      state.dsCmbMonedas = []
      state.dsCmbPreciosDefault = [
        { precioId: 1, precioNombre: 'precio_1' },
        { precioId: 2, precioNombre: 'precio_2' },
        { precioId: 3, precioNombre: 'precio_3' },
        { precioId: 4, precioNombre: 'precio_4' }
      ]
      state.dsCmbPerfiles = []
      state.dsCmbProductoSAT = []
      state.dsCmbRegimenesFiscales = []
      state.dsCmbSucursales = []
      state.dsCmbSucursalIVAVenta = []
      state.dsCmbTipoCuentaBancos = []
      state.dsCmbTipoPersona = []
      state.dsCmbTipoProducto = []
      state.dsCmbUnidadesMedida = []
      state.dsCmbUsoCFDI = []
      state.dsCmbUsuarios = []
    }
  }
})

export const {
  handleCombosOnLogout,
  handleDsClasificacionSuperiorContacto,
  handleDsClasificacionSuperiorProducto,
  handleDsCmbBancos,
  handleDsCmbContactos,
  handleDsCmbContactosSale,
  handleDsCmbCriteriosAntiguedad,
  handleDsCmbCuentasBancos,
  handleDsCmbFormasPago,
  handleDsCmbFormasPagoCuenta,
  handleDsCmbListaPrecio,
  handleDsCmbListaPrecioOnMenu,
  handleDsCmbMetodoPagoVenta,
  handleDsCmbMonedas,
  handleDsCmbPerfiles,
  handleDsCmbProductoSAT,
  handleDsCmbRegimenesFiscales,
  handleDsCmbSucursales,
  handleDsCmbSucursalIVAVenta,
  handleDsTipoCuentaBancos,
  handleDsCmbTipoPersona,
  handleDsCmbTipoProducto,
  handleDsCmbUnidadesMedida,
  handleDsCmbUsoCFDI,
  handleDsCmbUsuarios
} = combosSlice.actions

export default combosSlice.reducer
