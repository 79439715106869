import { createSlice } from '@reduxjs/toolkit'

export const productAndServiceClassificationsActivesSlice = createSlice({
  name: 'productAndServiceClassificationsActives',
  initialState: {
    productAndServiceClassificationsActives: []
  },
  reducers: {
    handleGetProductAndServiceClassificationsActives: (state, action) => {
      state.productAndServiceClassificationsActives = action.payload
    }
  }
})

export const { handleGetProductAndServiceClassificationsActives } =
  productAndServiceClassificationsActivesSlice.actions

export default productAndServiceClassificationsActivesSlice.reducer
