/* eslint-disable multiline-ternary */

import { createSlice } from '@reduxjs/toolkit'

export const productAndServiceClassificationsSlice = createSlice({
  name: 'productAndServiceClassifications',
  initialState: {
    productAndServiceClassifications: [],
    selectedProductAndServiceClassification: null,
    selectedProductAndServiceClassificationsStep: 'Product_And_Service_Classifications'
  },
  reducers: {
    handleCreateProductAndServiceClassification: (state, action) => {
      state.productAndServiceClassifications = [
        action.payload,
        ...state.productAndServiceClassifications
      ]
    },
    handleDeleteProductAndServiceClassification: (state, action) => {
      state.productAndServiceClassifications = [
        ...state?.productAndServiceClassifications?.slice(0, action.payload),
        ...state?.productAndServiceClassifications?.slice(action.payload + 1)
      ]
    },
    handleGetProductAndServiceClassification: (state, action) => {
      state.productAndServiceClassifications = state?.productAndServiceClassifications?.map((e) => {
        return e.clasificacionId.toString() === action.payload.clasificacionId.toString()
          ? action.payload
          : e
      })
    },
    handleGetProductAndServiceClassifications: (state, action) => {
      state.productAndServiceClassifications = action.payload
    },
    handleProductAndServiceClassificationsOnLogout: (state) => {
      state.productAndServiceClassifications = []
      state.selectedProductAndServiceClassification = null
      state.selectedProductAndServiceClassificationsStep = 'Product_And_Service_Classifications'
    },
    handleUpdateProductAndServiceClassifications: (state, action) => {
      state.productAndServiceClassifications = state?.productAndServiceClassifications?.map((e) => {
        return e.clasificacionId.toString() !== action.payload.clasificacionId.toString()
          ? { ...e, clasificacionPadreOptions: [] }
          : e
      })
    },
    handleSelectProductAndServiceClassification: (state, action) => {
      state.selectedProductAndServiceClassification = action.payload
    },
    handleSelectProductAndServiceClassificationsStep: (state, action) => {
      state.selectedProductAndServiceClassificationsStep = action.payload
    }
  }
})

export const {
  handleCreateProductAndServiceClassification,
  handleDeleteProductAndServiceClassification,
  handleGetProductAndServiceClassification,
  handleGetProductAndServiceClassifications,
  handleProductAndServiceClassificationsOnLogout,
  handleUpdateProductAndServiceClassifications,
  handleSelectProductAndServiceClassification,
  handleSelectProductAndServiceClassificationsStep
} = productAndServiceClassificationsSlice.actions

export default productAndServiceClassificationsSlice.reducer
