// ** I18n Imports
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n

  // Enables the i18next backend
  .use(Backend)

  // Enable automatic language detection
  .use(LanguageDetector)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || 'es',
    backend: {
      /* translation file path */
      loadPath: '/assets/data/locales/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    resources: {
      es: { translation: require('./../../assets/data/locales/es.json') },
      en: { translation: require('./../../assets/data/locales/en.json') }
    }
  })

export default i18n
